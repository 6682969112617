<template>
    <div>
        <!--   头部导航     -->
        <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>

        <div class="us-top" :class="{'mobile':screenWidth<=1400}">
            <div>
                <h2>加入我们 <span>JOIN US</span></h2>
                <p>欢迎有理想，有能力，敢拼搏的你加入我们的大家庭</p>
            </div>
        </div>

        <!--   福利待遇     -->
        <div class="benefits" :class="{'mobile':screenWidth<=1400}">
            <h3>福利待遇 <span>Fringe Benefits</span></h3>
            <div class="benefits-main">
                <div class="left">
                    <ul>
                        <li>
                            <span></span>
                            <h5>健康保险</h5>
                            <p>养老保险  医疗保险  实业保险  工伤保险  生育保险  住房公积金</p>
                        </li>
                        <li>
                            <span></span>
                            <h5>员工补助</h5>
                            <p>交通补助  餐饮补助</p>
                        </li>
                        <li>
                            <span></span>
                            <h5>员工活动</h5>
                            <p>定期聚餐  年度旅游  员工生日专属礼物  节假日福利</p>
                        </li>
                        <li>
                            <span></span>
                            <h5>办公环境</h5>
                            <p>五星级办公楼  团队年轻化</p>
                        </li>
                    </ul>
                </div>
                <div class="right" v-if="screenWidth>1400">
                    <img src="../assets/us-benefits-right.png" alt="">
                </div>
            </div>
        </div>



        <!--   招聘岗位     -->
        <div class="position" :class="{'mobile':screenWidth<=1400}">
            <h3>招聘岗位 <span>Recruitment position</span></h3>
            <div class="position-main">
                <div>
                    <img src="../assets/position-1.png" alt="">
                    <p>技术岗位</p>
                </div>
                <div>
                    <img src="../assets/position-2.png" alt="">
                    <p>招商人才</p>
                </div>
                <div>
                    <img src="../assets/position-3.png" alt="">
                    <p>管理精英</p>
                </div>
                <div>
                    <img src="../assets/position-4.png" alt="">
                    <p>办公行政</p>
                </div>
            </div>
            <a href="https://www.zhipin.com/sem/10.html" target="_blank">
                了解详细岗位需求
                <img src="../assets/position-more.png" alt="">
            </a>
        </div>




        <!--  底部  -->
        <footerC></footerC>
    </div>
</template>

<script>
    let _this;
    import headerC from "@/components/headerC.vue";
    import footerC from "@/components/footerC.vue";
    export default {
        name: "us",
        components: {
            headerC,
            footerC
        },
        created() {
            _this = this;
        },
        data(){
            return{
                header_fixed: false,
                nav_active: 'us',
                screenWidth: document.body.clientWidth,     // 屏幕宽
                scroll: '',
            }
        },
        watch: {
            scroll() {
                if (_this.scroll > 0) {
                    _this.header_fixed = true
                } else {
                    _this.header_fixed = false
                }
                console.log(_this.header_fixed)
            }
        },
        mounted() {
            // 监听页面滚动事件
            window.addEventListener('scroll', this.menu)


            // 监听窗口宽度变化
            window.addEventListener('resize', function () {
                window.screenWidth = document.body.clientWidth
                _this.screenWidth = window.screenWidth
                console.log(_this)
            })
        },
        methods:{
            menu() {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // console.log(this.scroll)

            },
        }
    }
</script>

<style lang="scss" scoped>
    .us-top {
        width: 100%;
        height: 450px;
        background-image: url("../assets/us-top-bg.png");
        background-size: 100% 100%;
        padding-top: 200px;
        padding-left: 5%;
        box-sizing: border-box;

        div {
            h2 {

                font-weight: bold;
                font-style: italic;
                font-size: 52px;
                color: #FFFFFF;

                span {

                    font-size: 36px;
                    color: #73ACFF;
                }
            }

            p {

                font-size: 20px;
                color: #C8DEFF;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }
    .us-top.mobile{
        background-image: url("../assets/us-top-mobile.png");
        height: calc(100vw * 0.75);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 100px 0 0 ;
        box-sizing: border-box;
        div{
            h2{

                font-size: 26px;
                text-align: center;
                span{
                    display: block;
                    font-size: 24px;

                }
            }
            p{
                display: none;
            }
        }
    }

    .benefits{
        max-width: 1600px;
        margin: 0 auto;
        padding-top: 50px;
        box-sizing: border-box;
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            span{
                display: inline-block;
                margin-left: 10px;
                color: #7D818C;
                font-weight: 400;
                font-size: 18px;
            }
        }
        .benefits-main{
            display: flex;
            justify-content: space-between;
            margin-bottom: 60px;
            .left{
                width: 49%;
                margin-top: 40px;
                ul{
                    border: 1px solid #E1E6F0;
                    li{

                        background: #F9FAFF;
                        height: 105px;
                        border-bottom: 1px solid #E1E6F0;
                        display: flex;
                        align-items: center;
                        padding: 0 30px;
                        box-sizing: border-box;
                        span{
                            display: block;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: #0B6FFF;
                            margin-right: 20px;
                        }
                        h5{
                            margin-right: 50px;
                            font-size: 20px;
                            font-weight: 400;
                            color: #333333;
                        }
                        p{

                            font-weight: 400;
                            color: #666666;
                            font-size: 16px;
                        }
                    }
                    li:last-of-type{
                        border-bottom: 0;
                    }
                }
            }
            .right{
                width: 48%;
                img{
                    width: 100%;
                }
            }
        }
    }
    .benefits.mobile{
        padding: 0 30px;
        box-sizing: border-box;
        h3{
            font-size: 22px;
            span{

                font-size: 14px;
            }
        }
        .benefits-main{
            display: block;
            .left{
                width: 100%;
                ul{
                    li{
                        display: block;
                        padding-top: 20px;
                        box-sizing: border-box;
                        span{
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: #0B6FFF;
                            margin-right: 10px;
                        }
                        h5{
                            display: inline-block;
                            width: 150px;
                            margin-right: 0;
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                        }
                        p{
                            margin-top: 10px;
                            font-weight: 400;
                            color: #666666;
                            font-size: 14px;
                        }

                    }
                }
            }
        }
    }
    .position{
        max-width: 1600px;
        margin: 0 auto;
        padding-top: 50px;
        box-sizing: border-box;
        margin-bottom: 100px;
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            span{
                display: inline-block;
                margin-left: 10px;
                color: #7D818C;
                font-weight: 400;
                font-size: 18px;
            }
        }
        .position-main{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            div{
                padding: 2% 5%;
                box-sizing: border-box;
                border: 1px solid #E1E6F0;
                background-color: #E1E6F0;
                width: 25%;
                img{
                    width: 100%;
                }
                p{

                    font-size: 24px;
                    font-weight: 400;
                    color: #555555;
                    text-align: center;
                }
            }
            div:nth-of-type(2n){
                background-color: #fff;
            }
        }
        &>a{
            border: 1px solid #E1E6F0;
            border-top: 0;
            height: 100px;
            line-height: 100px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #0B6FFF;
            font-weight: 400;
            cursor: pointer;
            img{
                margin-left: 12px;
            }
        }

    }
    .position.mobile{
        padding: 0 30px;
        box-sizing: border-box;
        h3{
            font-size: 22px;
            span{

                font-size: 14px;
            }
        }
        .position-main{
            display: block;
            div{
                width: 100%;
                p{
                    font-size: 20px;
                }
            }
        }
        &>a{
            font-size: 20px;
        }
    }
</style>