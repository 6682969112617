<template>
    <div class="join">

        <!--   头部导航     -->
        <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>

        <div class="join-top" :class="{'mobile':screenWidth<=1400}">
            <div>
                <h2>招商加盟 <span>MERCHANTS JOIN</span></h2>
                <p>诚邀全国有识之士通过B2b、B2c模式加入九州，为梦想添上翅膀，扬帆起航</p>
				 <p>联系人：范经理</p>
				 <p>联系电话：15550063222</p>
            </div>
        </div>


        <!--加盟背景-->
        <div class="background" id="background" v-if="screenWidth>1400">
            <ul class="top-link">
                <li class="active" @click="intoView('background')">加盟背景</li>
                <li @click="intoView('advantage')">加盟优势</li>
                <li @click="intoView('fee')">加盟费用</li>
                <li @click="intoView('conditions')">加盟条件</li>
                <li @click="intoView('process')">合作流程</li>
            </ul>
            <h3>加盟背景</h3>
            <p>Joining background</p>
            <div class="background-main">
                <div class="left">
                    <div>
                        <h4>01 <span>市场格局</span></h4>
                        <p>从整个市场格局上看，整个代驾行业已经从最初的百家争鸣来到了强者对决的阶段。</p>
                    </div>
                    <div>
                        <h4>02 <span>市场竞争</span></h4>
                        <p>从市场竞争环境上看，代驾行业经过初创期的资本狂欢之后，竞争越来越激烈，现逐渐步入了稳定发展期</p>
                    </div>
                </div>
                <div class="right">
                    <div>
                        <h4><span>行业发展</span> 03</h4>
                        <p>从行业发展前景看，代驾行业订单总量和总产值都在逐年攀升，预计未来，中国代驾市场将迎来高达100倍的扩容，形成一个千亿级大市场。</p>
                    </div>
                    <div>
                        <h4><span>行业规范</span> 04</h4>
                        <p>从行业规范上来看，相关各方对整个行业的管束将愈加严厉，这使得代驾行业也逐步规范。</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="background-mobile" v-else>
            <h3>加盟背景</h3>
            <p>Franchise advantage</p>
            <div class="background-main">
                <img src="../assets/join-background-1.png" alt="">
                <div>
                    <h4>市场格局 <span>01</span></h4>
                    <p>从整个市场格局上看，整个代驾行业已经从最初的百家争鸣来到了强者对决的阶段。</p>
                </div>
            </div>
            <div class="background-main">
                <img src="../assets/join-background-2.png" alt="">
                <div>
                    <h4>市场竞争 <span>02</span></h4>
                    <p>从市场竞争环境上看，代驾行业经过初创期的资本狂欢之后，竞争越来越激烈，现逐渐步入了稳定发展期</p>
                </div>
            </div>
            <div class="background-main">
                <img src="../assets/join-background-3.png" alt="">
                <div>
                    <h4>行业发展 <span>03</span></h4>
                    <p>从行业发展前景看，代驾行业订单总量和总产值都在逐年攀升，预计未来，中国代驾市场将迎来高达100倍的扩容，形成一个千亿级大市场。</p>
                </div>
            </div>
            <div class="background-main">
                <img src="../assets/join-background-4.png" alt="">
                <div>
                    <h4>行业规范 <span>04</span></h4>
                    <p>从行业规范上来看，相关各方对整个行业的管束将愈加严厉，这使得代驾行业也逐步规范。</p>
                </div>
            </div>
        </div>

        <!--    加盟优势    -->
        <div class="advantage-max" :class="{'mobile':screenWidth<=1400}">
            <div class="advantage" id="advantage">
                <h3>加盟优势</h3>
                <p>Franchise advantage</p>
                <div class="advantage-main">
                    <div class="left">
                        <div>
                            <h4>市场优势</h4>
                            <p>
                                从中国的市场经济模式来分析，在人口规模和市场需求的双向牵引下，只要是座城，就有代驾需求。作为服务行业，良性竞争开始，代驾开始注重保险、服务等各方面的发展，代驾公司可以迅速抢到市场份额。例如石家庄，每天大约4000-5000单；济南，每天大约2000-3000单。
                            </p>
                        </div>
                        <div>
                            <h4>服务优势</h4>
                            <p>
                                建立有完善的培训 体系，从多个方面，严格的岗前、岗中、岗后 培训体系，规范的服务流程和制度体系，学习 型企业组织的构架体系。九州集优秀讲师大咖团
                                队，配合完善的营销体系，协助合作伙伴完成蜕变转化。
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <div style="margin-top: 360px">
                            <h4>技术优势</h4>
                            <p>
                                总部拥有专业的研发团队，独有的代驾系统，在开发上注重各种 因素，不断创立研发新功能；专业的技术团队可为您提供全面的技术培训，提高店面的竞争力。
                            </p>
                        </div>
                        <div>
                            <h4>推广优势</h4>
                            <p>
                                九州代驾领先一步的策略及合理的管理模式，独特的地推模式+九州总部企业助力，九州运用“海、陆、空”三位一体宣传模式，受众率高，结合市场实际情况量身定制推广方案。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!--    加盟费用    -->
        <div class="fee" id="fee" :class="{'mobile':screenWidth<=1400}">
            <h3>加盟费用</h3>
            <p>Franchise fee</p>
            <div class="fee-main">
                <img src="../assets/fee-left.png" alt="" v-if="screenWidth>1400">
                <div>
                    <div :style="{transform: screenWidth>1400?'translateX(-100px)':'0'}">
                        <h5>A级公司前期筹备组件</h5>
                        <div>
                            <p>· 筹备期两个月；固定支出12万；流动资金20万；市场推广8万。</p>
                            <p>· 代驾员招募，运营10个月招募640人</p>
                        </div>
                    </div>

                    <div :style="{transform: screenWidth>1400?'translateX(-50px)':'0'}">
                        <h5>B级公司前期筹备组件</h5>
                        <div>
                            <p>· 筹备期两个月；固定支出6万；流动资金10万；市场推广3万。</p>
                            <p>· 代驾员招募，运营10个月招募200人。</p>
                        </div>
                    </div>
                    <div>
                        <h5>C级公司前期筹备组件</h5>
                        <div>
                            <p>· 筹备期两个月；固定支出3万；流动资金5万；市场推广1万。 </p>
                            <p>· 代驾员招募，运营10个月招募80人。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--    加盟条件    -->
        <div class="conditions-max"  :class="{'mobile':screenWidth<=1400}">
            <div class="conditions" id="conditions">
                <h3>加盟条件</h3>
                <p>Franchise conditions</p>
                <div class="conditions-main">
                    <div class="left">
                        近年来，随着国家、政府大力推进 “ 大众创业 万众创新 ”，以新经济新业态新模式为主导的创业创新型小微企业发展如火如荼，极大地激发了市场经济潜能。
                        九州代驾开放全国各级区域加盟，致力打造优质服务、优值价格，来为有识之士打造创业平台。我们拥有强大的专业团队、成熟的管理制度与市场经验，完全可以满足所有客户的需求，现为扩大市场，面向全国诚招加盟 。
                    </div>
                    <ul class="right">
                        <li><span></span>认可代驾行业，认可九州代驾，有一定的基础创业经验，相信代驾行业的市场需求；</li>
                        <li><span></span>有创业的欲望和信心，能坚持努力工作；</li>
                        <li><span></span>有一定的经济基础和客户资源；</li>
                        <li><span></span>具备公司法人资格的企业或个人；</li>
                    </ul>
                </div>
            </div>
        </div>

        <!--    合作流程    -->
        <div class="process" id="process" v-if="screenWidth>1400">
            <h3>合作流程</h3>
            <p>Cooperation process</p>
            <div class="process-main">
                <img src="../assets/process-1.png" alt="">
                <img src="../assets/process-left.png" alt="" class="link">
                <img src="../assets/process-2.png" alt="" class="down">
                <img src="../assets/process-right.png" alt="" class="link">
                <img src="../assets/process-3.png" alt="">
                <img src="../assets/process-left.png" alt="" class="link">
                <img src="../assets/process-4.png" alt="" class="down">
                <img src="../assets/process-right.png" alt="" class="link">
                <img src="../assets/process-5.png" alt="">
            </div>
        </div>


        <!--  底部  -->
        <footerC></footerC>
    </div>
</template>

<script>
    let _this;
    import headerC from "@/components/headerC.vue";
    import footerC from "@/components/footerC.vue";

    export default {
        name: "join",
        components: {
            headerC,
            footerC
        },
        created() {
            _this = this;
        },
        data() {
            return {
                header_fixed: false,
                nav_active: 'join',
                screenWidth: document.body.clientWidth,     // 屏幕宽
                scroll: '',
            }
        },
        watch: {
            scroll() {
                if (_this.scroll > 0) {
                    _this.header_fixed = true
                } else {
                    _this.header_fixed = false
                }
                console.log(_this.header_fixed)
            }
        },
        mounted() {
            // 监听页面滚动事件
            window.addEventListener('scroll', this.menu)


            // 监听窗口宽度变化
            window.addEventListener('resize', function () {
                window.screenWidth = document.body.clientWidth
                _this.screenWidth = window.screenWidth
                console.log(_this)
            })
        },
        methods: {
            menu() {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // console.log(this.scroll)

            },
            // 锚记链接方法
            intoView(val) {
                document.getElementById(val).scrollIntoView();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .background-mobile,
    .advantage.mobile,
    .fee.mobile,
    .process.mobile{
        h3{
            text-align: center;
            font-size: 28px;
            font-weight: 400;
            color: #333333;
        }
        p{
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: #7D818C;
        }
    }

    .join-top {
        width: 100%;
        height: 450px;
        background-image: url("../assets/about-top-bg.png");
        background-size: 100% 100%;
        padding-top: 200px;
        padding-left: 5%;
        box-sizing: border-box;

        div {
            h2 {

                font-weight: bold;
                font-style: italic;
                font-size: 52px;
                color: #FFFFFF;

                span {

                    font-size: 36px;
                    color: #73ACFF;
                }
            }

            p {

                font-size: 20px;
                color: #C8DEFF;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }
    .join-top.mobile{
        background-image: url("../assets/join-top-mobile.png");
        height: calc(100vw * 0.75);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 100px 0 0 ;
        box-sizing: border-box;
        div{
            h2{

                font-size: 26px;
                text-align: center;
                span{
                    display: block;
                    font-size: 24px;

                }
            }
            p{
                display: none;
            }
        }
    }

    .background{
        max-width: 1600px;
        margin: 0 auto;
        .top-link{
            display: flex;
            border-bottom: 1px solid #E3E5E8;
            li{
                height: 77px;
                line-height: 77px;
                margin: 0 20px;
                cursor: pointer;
            }
            .active{
                border-bottom: 3px solid #0B6FFF;
            }
        }
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            text-align: center;
            margin-top: 100px;
        }
        p{
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
            color: #7D818C;
            font-weight: 400;
        }
        .background-main{
            display: flex;
            justify-content: space-between;
            background-image: url("../assets/join-background-bg.png");
            background-repeat: no-repeat;
            background-position: center;
            height: 566px;
            .left,.right{
                width: 40%;
                h4{

                    font-size: 60px;
                    font-weight: bold;
                    color: rgba(11, 111, 255, .1);
                    span{

                        font-size: 24px;
                        color: #0B6FFF;
                        font-weight: 400;
                    }
                }
                p{

                    font-size: 16px;
                    color: #555555;
                    font-weight: 400;
                }
                &>div:nth-of-type(1){
                    margin-bottom: 280px;
                }
            }
            .left{
                padding-left: 137px;
                box-sizing: border-box;
                div{
                    float: right;
                    h4{
                        text-align: right;
                    }
                    p{
                        text-align: right;
                    }
                }
            }
            .right{
                padding-right: 137px;
                box-sizing: border-box;
                p{
                    text-align: left;
                }
            }
        }
    }
    .background-mobile{
        .background-main{
            display: flex;
            padding: 0 20px;
            justify-content: space-between;
            box-sizing: border-box;
            margin: 20px 0;
            img{
                width: 40%;
            }
            div{
                width: 58%;
                h4{

                    font-size: 20px;
                    font-weight: 400;
                    color: #0B6FFF;
                    span{

                        color: #0B6FFF;
                        opacity: 0.1;
                        font-weight: bold;
                        font-size: 30px;
                    }
                }
                p{

                    font-size: 14px;
                    color: #555555;
                    font-weight: 400;
                    text-align: left;
                }
            }
        }
    }

    .advantage-max{
        width: 100%;
        background: #F6F8FB;
        margin-top: 100px;
        .advantage{
            max-width: 1600px;
            margin: 0 auto;
            padding-top: 100px;
            box-sizing: border-box;
            background-image: url("../assets/advantage-bg.png");
            background-position: center 300px;
            background-repeat: no-repeat;
            height: 1200px;
            h3{

                font-size: 32px;
                color: #333333;
                font-weight: 400;
                text-align: center;
            }
            p{
                text-align: center;
                margin-top: 10px;
                font-size: 18px;
                color: #7D818C;
                font-weight: 400;
            }
            .advantage-main{
                display: flex;
                justify-content: space-between;
                &>div{
                    width: 40%;
                }
                .left{
                    div{
                        margin-top: 190px;
                        h4{
                            text-align: right;
                            font-size: 24px;
                            color: #0B6FFF;
                            font-weight: 400;
                        }
                        p{
                            text-align: right;
                            font-size: 16px;
                            color: #555555;
                            font-weight: 400;
                        }
                    }
                }
                .right{
                    div{
                        margin-top: 300px;
                        h4{
                            text-align: left;
                            font-size: 24px;
                            color: #0B6FFF;
                            font-weight: 400;
                        }
                        p{
                            text-align: left;
                            font-size: 16px;
                            color: #555555;
                            font-weight: 400;
                        }
                    }
                }
            }
        }

    }
    .advantage-max.mobile{
        .advantage{
            background-image: unset;
            height: auto;
            .advantage-main{
                display: block;
                .left,
                .right{
                    width: 100%;
                    div{
                        margin: 20px 0!important;
                        h4{
                            text-align: center;
                        }
                        p{
                            text-align: center;
                        }
                    }
                }

            }
        }
    }

    .fee{
        max-width: 1600px;
        margin: 0 auto;
        padding-top: 100px;
        box-sizing: border-box;
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            text-align: center;
        }
        p{
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
            color: #7D818C;
            font-weight: 400;
        }
        .fee-main{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-top: 20px;
            box-sizing: border-box;
            &>img{
                width: 27%;
            }
            &>div{
                padding-top: 50px;
                box-sizing: border-box;
                &>div{
                    display: flex;
                    height: 30%;
                    h5{

                        font-size: 24px;
                        color: #377DEC;
                        font-weight: 400;
                    }
                    p{
                        font-size: 16px;
                        color: #555555;
                        text-align: left;
                        margin-left: 65px;
                    }
                }
            }

        }
    }
    .fee.mobile{
        padding: 0 20px;
        box-sizing: border-box;
        .fee-main{
            h5{
                font-size: 18px;
            }
            div{

                    p{
                        font-size: 14px
                    }

            }

        }
    }

    .conditions-max{
        width: 100%;
        background: #F6F8FB;
        .conditions{
            padding: 100px 0;
            box-sizing: border-box;
            max-width: 1600px;
            margin: 0 auto;
            h3{

                font-size: 32px;
                color: #333333;
                font-weight: 400;
                text-align: center;
            }
            p{
                text-align: center;
                margin-top: 10px;
                font-size: 18px;
                color: #7D818C;
                font-weight: 400;
            }
            .conditions-main{
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
                .left{
                    width: 48%;
                    padding: 40px;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #FFFFFF;
                    font-weight: 400;
                    background:#0B6FFF;
                    line-height: 40px;
                }
                .right{
                    width: 48%;
                    li{
                        height: 61px;
                        line-height: 61px;
                        border-bottom: 1px dashed #C5C6C9;
                        span{
                            display: inline-block;
                            width: 3px;
                            height: 3px;
                            background-color: #0B6FFF;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .conditions-max.mobile{
        .conditions-main{
            display: block;
            .left{
                width: 100%;
            }
            .right{
                width: 100%;
                li{
                    height: auto;
                }
            }
        }
    }

    .process{
        max-width: 1600px;
        margin: 0 auto;
        padding: 100px 0;
        box-sizing: border-box;
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            text-align: center;
        }
        p{
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
            color: #7D818C;
            font-weight: 400;
        }
        .process-main{
            display: flex;
            justify-content: center;
            padding-top: 50px;
            box-sizing: border-box;
            .link{
                height: 43px;
                transform: translateY(50px);
            }
            .down{
                transform: translateY(30px);
            }
        }
    }
    .process.mobile{

    }
</style>