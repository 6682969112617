<template>
    <div>
        <!--   头部导航     -->
        <headerC :header_fixed="header_fixed" :nav_active="nav_active"></headerC>


        <div class="contact-top" :class="{'mobile':screenWidth<=1400}">
            <div>
                <h2>联系方式 <span>CONTACT</span></h2>
                <p>如果您有什么问题和意见可以及时联系我们</p>
            </div>
        </div>


        <div class="main" :class="{'mobile':screenWidth<=1400}">
            <h3>九州总部 <span>Jiuzhou Headquarters</span></h3>
            <div class="main-top">
                <div>
                    <img src="../assets/contact-icon.png" alt="">
                    <p>济南市长清区西部创新园A座</p>
                </div>
                <h4 v-if="screenWidth>1400">COMPANY ADDRESS</h4>
            </div>
            <div class="map">
                <img src="../assets/contact-map.png" alt="">
            </div>
            <div class="main-bottom">
                <div>
                    <img src="../assets/contact-bottom-1.png" alt="">
                    <p>招商加盟  155 5006 3222</p>
                </div>
                <div>
                    <img src="../assets/contact-bottom-2.png" alt="">
                    <p>jzhoudaijia@163.com</p>
                </div>
                <div>
                    <img src="../assets/contact-bottom-3.png" alt="">
                    <p>公众号  <span>九州代驾服务号</span></p>
                </div>
            </div>
        </div>

        <!--  底部  -->
        <footerC></footerC>
    </div>
</template>

<script>
    let _this;
    import headerC from "@/components/headerC.vue";
    import footerC from "@/components/footerC.vue";
    export default {
        name: "contact",
        components: {
            headerC,
            footerC
        },
        created() {
            _this = this;
        },
        data(){
            return{
                header_fixed: false,
                nav_active: 'contact',
                screenWidth: document.body.clientWidth,     // 屏幕宽
                scroll: '',
            }
        },
        watch: {
            scroll() {
                if (_this.scroll > 0) {
                    _this.header_fixed = true
                } else {
                    _this.header_fixed = false
                }
                console.log(_this.header_fixed)
            }
        },
        mounted() {
            // 监听页面滚动事件
            window.addEventListener('scroll', this.menu)


            // 监听窗口宽度变化
            window.addEventListener('resize', function () {
                window.screenWidth = document.body.clientWidth
                _this.screenWidth = window.screenWidth
                console.log(_this)
            })
        },
        methods:{
            menu() {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
                // console.log(this.scroll)

            },
        }
    }
</script>

<style lang="scss" scoped>
    .contact-top {
        width: 100%;
        height: 450px;
        background-image: url("../assets/contact-top-bg.png");
        background-size: 100% 100%;
        padding-top: 200px;
        padding-left: 5%;
        box-sizing: border-box;

        div {
            h2 {

                font-weight: bold;
                font-style: italic;
                font-size: 52px;
                color: #FFFFFF;

                span {

                    font-size: 36px;
                    color: #73ACFF;
                }
            }

            p {

                font-size: 20px;
                color: #C8DEFF;
                font-weight: 400;
                margin-top: 10px;
            }
        }
    }
    .contact-top.mobile{
        background-image: url("../assets/contact-top-mobile.png");
        height: calc(100vw * 0.75);
        background-size: 100%;
        background-repeat: no-repeat;
        padding: 100px 0 0 ;
        box-sizing: border-box;
        div{
            h2{

                font-size: 26px;
                text-align: center;
                span{
                    display: block;
                    font-size: 24px;

                }
            }
            p{
                display: none;
            }
        }
    }

    .main{
        max-width: 1600px;
        margin: 0 auto;
        padding-top: 80px;
        box-sizing: border-box;
        h3{

            font-size: 32px;
            color: #333333;
            font-weight: 400;
            span{

                font-size: 18px;
                font-weight: 400;
                color: #7D818C;
                margin-left: 10px;
            }
        }
        .main-top{
            display: flex;
            justify-content: space-between;
            padding: 40px 0 ;
            box-sizing: border-box;
            border-bottom: 1px solid #E2E5EA;
            margin-bottom: 40px;
            div{
                display: flex;
                img{
                    width: 30px;
                    height: 28px;
                    margin-right: 10px;
                }
                p{

                    font-size: 18px;
                    color: #555555;
                    font-weight: 400;
                }
            }
        }
        .map{
            img{
                width: 100%;
            }
        }
        .main-bottom{
            display: flex;
            justify-content: space-around;
            padding: 40px 0 ;
            box-sizing: border-box;
            border-bottom: 1px solid #E2E5EA;
            margin-bottom: 80px;
            div{
                display: flex;
                align-items: center;
                width: 30%;
                img{
                    width: 72px;
                    height: 72px;
                    margin-right: 30px;
                }
                p{
                    font-size: 18px;
                    color: #333333;
                    font-weight: 400;
                    &>span{
                        font-weight: bold;
                        display: inline-block;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .main.mobile{
        padding: 0 30px;
        box-sizing: border-box;
        h3{
            font-size: 22px;
            span{

                font-size: 14px;
            }
        }
        .main-top{
            display: block;
            padding: 20px 0;
            box-sizing: border-box;
        }
        .main-bottom{
            display: block;
            div{
                width: 100%;
                margin: 20px 0 ;
            }
        }
    }
</style>